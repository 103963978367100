import React, { useCallback, useContext, useState } from "react";
import { AuthContext } from "react-oauth2-code-pkce";
import { useTranslation } from "react-i18next";
import OrgStore from "../stores/OrgStore";
import {
  Alert,
  Button,
  Card,
  CardContent,
  IconButton,
  List,
  ListItem,
  TextField,
  Typography,
} from "@mui/material";
import AuthStore from "../stores/AuthStore";
import AddIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import SearchIcon from "@mui/icons-material/Search";
import ErrorIcon from "@mui/icons-material/Error";
import CheckIcon from "@mui/icons-material/Check";
import { IUser } from "../models/user.types";
import { getListOfContacts, isValidEmail } from "../utils/helpers";
import InviteUserDetails from "./InviteUserDetails";

interface RefreshRequestor {
  requestRefreshCallback: (refresh: boolean) => void;
}

const AddUserToCurrentOrganization: React.FC<RefreshRequestor> = ({
  requestRefreshCallback,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { token } = useContext(AuthContext);
  const { t } = useTranslation();
  const [dirty, setDirty] = useState<boolean>(false);
  const [alert, setAlert] = useState<string | undefined>(undefined);
  const [isErrorAlert, setErrorAlert] = useState<boolean>(false);
  const [existingUsersFound, setExistingUsersFound] = useState<IUser[]>([]);
  const [inviteUser, setInviteUser] = useState<boolean>(false);
  const [inviteFieldEnabled, setInviteFieldEnabled] = useState<boolean>(false);
  const [inviteUserEmail, setInviteUserEmail] = useState<string>();
  const handleUserFieldChange = (event) => {
    setSearchTerm(event.target.value);
    setDirty(true);
    setAlert(undefined);
  };

  const handleSearch = useCallback(async () => {
    if (dirty) {
      try {
        setExistingUsersFound([]);
        setInviteUser(false);
        setErrorAlert(false);

        const usersFound = await OrgStore.searchForUser(searchTerm, token);
        // Now 3 things might happen
        //  - user(s) are found, and some of them are already in the organization - ignore those
        //  - users are found and are not in the organization yet - add option to add them
        //  - no users are found at all, we shall invite only in this case
        // in case there is no new user to add (but some are existing) show error message

        if (!usersFound?.length) {
          console.log("No users found...");
          if (isValidEmail(searchTerm)) {
            setInviteUser(true);
          } else {
            setErrorAlert(true);
            setAlert(t("user.not_existing_cannot_add"));
          }

          setExistingUsersFound([]);
          setDirty(false);
          return;
        }

        // filter those who already exists in the organization
        const externalExistingUsers = usersFound?.filter(
          (user) =>
            !OrgStore.users?.content.find((orgUser) => orgUser.id === user.id),
        );
        setExistingUsersFound(externalExistingUsers ?? []);

        if (!externalExistingUsers || externalExistingUsers.length === 0) {
          setErrorAlert(true);
          setAlert(t("user.no_new_user"));
        }
        // setAlert(t('result.success'));
      } catch (e) {
        setErrorAlert(true);
        setAlert(t("result.error", { errorMessage: (e as Error).message }));
      }
      setSearchTerm("");
      setDirty(false);
    }
  }, [dirty, searchTerm, t]);

  const handleInvite = useCallback(async () => {
    setInviteFieldEnabled(true);
    setInviteUserEmail(searchTerm);
    setInviteUser(false);
    setErrorAlert(false);
  }, [searchTerm]);

  const handleAdd = useCallback(
    async (userToAdd: string) => {
      try {
        await OrgStore.addUser(
          {
            id: userToAdd,
            roles: [],
            idType: "",
          },
          token,
        );
        const filteredUsersFound = existingUsersFound.filter(
          (user) => user.id != userToAdd,
        );
        setExistingUsersFound(filteredUsersFound);

        setErrorAlert(false);
        setAlert(t("result.success"));
      } catch (e) {
        setErrorAlert(true);
        setAlert(t("result.error", { errorMessage: (e as Error).message }));
      }
      requestRefreshCallback(true);
    },
    [existingUsersFound, t, requestRefreshCallback],
  );

  return (
    <List>
      {AuthStore.isOrganisationAdmin() && (
        <>
          <ListItem>
            <Typography>{t("org.add_user")}</Typography>
          </ListItem>
          <ListItem>
            <TextField
              value={searchTerm}
              onChange={handleUserFieldChange}
              sx={{ width: 300 }}
              label={t("org.find_user")}
              inputProps={{ onKeyDown: (e) => e.stopPropagation() }}
            ></TextField>

            <IconButton
              disabled={!dirty}
              onClick={handleSearch}
              color="primary"
            >
              <SearchIcon />
            </IconButton>
          </ListItem>
          {inviteUser && !dirty && (
            <ListItem>
              <Typography>{t("user.not_existing")}</Typography>
              <Button onClick={handleInvite} color="primary">
                {t("user.invite")}
              </Button>
            </ListItem>
          )}
          {inviteFieldEnabled && (
            <InviteUserDetails emailAddress={inviteUserEmail as string} />
          )}
          {existingUsersFound.length > 0 && (
            <>
              <ListItem>
                <Typography>{t("org.select_user")}</Typography>
              </ListItem>
              {existingUsersFound.map((user: IUser) => (
                <ListItem key={user.id}>
                  <Card sx={{ width: "100%" }}>
                    <CardContent>
                      <Typography>{"id :" + user.id}</Typography>
                      {getListOfContacts(user).map((item) => (
                        <Typography key={item.type + ": " + item.contact}>
                          {item.type + ": " + item.contact}
                        </Typography>
                      ))}
                      <Button
                        onClick={() => handleAdd(user.id)}
                        color="primary"
                        startIcon={<AddIcon />}
                      >
                        {t("org.add_user_button")}
                      </Button>
                    </CardContent>
                  </Card>
                </ListItem>
              ))}
            </>
          )}
        </>
      )}

      {alert && (
        <ListItem>
          <Alert
            icon={
              isErrorAlert ? (
                <ErrorIcon fontSize="inherit" />
              ) : (
                <CheckIcon fontSize="inherit" />
              )
            }
            severity={isErrorAlert ? "error" : "success"}
          >
            {alert}
          </Alert>
        </ListItem>
      )}
    </List>
  );
};

export default AddUserToCurrentOrganization;

import React from 'react';
import { observer } from 'mobx-react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SettingsStore from '../stores/SettingsStore';
import { CssBaseline } from '@mui/material';

const CustomThemeProvider = observer(({ children }: { children: React.ReactNode }) => {
  const theme = createTheme(SettingsStore.theme);

  React.useEffect(() => {
    const root = document.documentElement;

    root.style.setProperty('--primary-main', theme.palette.primary.main);
    root.style.setProperty('--primary-light', theme.palette.primary.light || theme.palette.primary.main);
    root.style.setProperty('--primary-contrast', theme.palette.primary.contrastText);

    root.style.setProperty('--secondary-main', theme.palette.secondary.main);
    root.style.setProperty('--secondary-light', theme.palette.secondary.light || theme.palette.secondary.main);
    root.style.setProperty('--secondary-contrast', theme.palette.secondary.contrastText);

    root.style.setProperty('--error-main', theme.palette.error.main);
    root.style.setProperty('--error-light', theme.palette.error.light || theme.palette.error.main);
    root.style.setProperty('--error-contrast', theme.palette.error.contrastText);

    root.style.setProperty('--success-main', theme.palette.success.main);
    root.style.setProperty('--success-light', theme.palette.success.light || theme.palette.success.main);
    root.style.setProperty('--success-contrast', theme.palette.success.contrastText);

    root.style.setProperty('--warning-main', theme.palette.warning.main);
    root.style.setProperty('--warning-light', theme.palette.warning.light || theme.palette.warning.main);
    root.style.setProperty('--warning-contrast', theme.palette.warning.contrastText);

    root.style.setProperty('--info-main', theme.palette.info.main);
    root.style.setProperty('--info-light', theme.palette.info.light || theme.palette.info.main);
    root.style.setProperty('--info-contrast', theme.palette.info.contrastText);

  }, [theme]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
});

export default CustomThemeProvider;

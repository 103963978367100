import { Box } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';

const NavLogo: React.FC = observer(() => {
  const navigate = useNavigate();
  return <Box component="img"
              sx={{
                height: 60,
                width: 'auto',
                marginLeft: 20,
                marginRight: 20
              }}
              src={'/shareguard.png'}
              alt="Logo"
              onClick={() => navigate("/")}
  />
});

export default NavLogo;
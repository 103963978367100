import React, { useContext, useEffect, useState } from "react";
import { IUser } from "../models/user.types";
import { AuthContext } from "react-oauth2-code-pkce";
import { useTranslation } from "react-i18next";
import OrgStore from "../stores/OrgStore";
import { Alert, List, ListItem, Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import CheckIcon from "@mui/icons-material/Check";
import { getListOfContacts } from "../utils/helpers";

interface AdminDetailsProps {
  id: string;
}

const AdminDetails: React.FC<AdminDetailsProps> = ({ id }) => {
  const [loading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useState<IUser>();
  const [contacts, setContacts] = useState<{ type: string; contact: string }[]>(
    [],
  );
  const { token } = useContext(AuthContext);
  const { t } = useTranslation();
  const [alert, setAlert] = useState<string | undefined>(undefined);
  const [isErrorAlert, setErrorAlert] = useState<boolean>(false);

  useEffect(() => {
    const fetch = async () => {
      const userDetails =
        (await OrgStore.getGlobalUser(id, token)) ?? undefined;
      setUserDetails(userDetails ?? undefined);
      setContacts(getListOfContacts(userDetails));
      setLoading(false);
    };
    setLoading(true);
    setUserDetails(undefined);
    setContacts([]);
    if (id && token) {
      fetch().catch((e) => {
        setErrorAlert(true);
        setAlert(t("result.error", { errorMessage: (e as Error).message }));
      });
    }
  }, [id, token]);

  return loading ? (
    <List>
      <ListItem>
        <Typography>{t("loading")}</Typography>
      </ListItem>
    </List>
  ) : (
    <List>
      <ListItem>
        <Typography>{"Id: " + id}</Typography>
      </ListItem>
      {contacts?.map((item) => (
        <ListItem key={item.type + ": " + item.contact}>
          <Typography>{item.type + ": " + item.contact}</Typography>
        </ListItem>
      ))}
      {alert && (
        <ListItem>
          <Alert
            icon={
              isErrorAlert ? (
                <ErrorIcon fontSize="inherit" />
              ) : (
                <CheckIcon fontSize="inherit" />
              )
            }
            severity={isErrorAlert ? "error" : "success"}
          >
            {alert}
          </Alert>
        </ListItem>
      )}
    </List>
  );
};

export default AdminDetails;

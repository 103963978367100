import React from "react";
import { useTranslation } from "react-i18next";
import OrgStore from "../stores/OrgStore";
import { Chip, List, ListItem, Stack, Typography } from "@mui/material";

const CurrentOrganizationDetails: React.FC = () => {
  const { t } = useTranslation();
  const organizationDetails = OrgStore.currentOrganisation;

  return (
    <List>
      <ListItem>
        <Typography>{"Id: " + organizationDetails?.id}</Typography>
      </ListItem>
      <ListItem>
        <Typography>{"Name: " + organizationDetails?.name}</Typography>
      </ListItem>
      <ListItem>
        <Typography>{t("org.available_roles") + ":"}</Typography>
      </ListItem>
      <ListItem>
        <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
          {organizationDetails?.assignableRoles.map((role) => (
            <Chip
              color={role === "ROLE_ORGANIZATION_ADMIN" ? "error" : "primary"}
              variant="outlined"
              key={role}
              label={role}
            />
          ))}
        </Stack>
      </ListItem>
    </List>
  );
};

export default CurrentOrganizationDetails;

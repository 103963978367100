import {  Box, Toolbar } from '@mui/material';
import React, { useContext } from 'react';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';
import { observer } from 'mobx-react';
import ProfileMenu from './ProfileMenu';
import NavLogo from './NavLogo';

const TopNavBar: React.FC = observer(() => {
  const { token }: IAuthContext = useContext(AuthContext)

  return (
    <Toolbar className='toolbar' color="primary">
      <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <NavLogo />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {token ? <ProfileMenu /> : null}
      </Box>
    </Toolbar>
  );
});

export default TopNavBar;
import { IUser, IUserWithRoles } from "src/models/user.types";
import { http } from "../utils/http/http";
import { t } from "i18next";
import { IOrgWithRoles } from "../models/org.types";

const baseUrl: string = process.env.REACT_APP_LOGIN_ENDPOINT || "";

const endpoints = {
  get: "/api/organizations/{{id}}",
  update: "/api/organizations/{{id}}",
  delete: "/api/organizations/{{id}}",
  add_admin: "/api/organizations/{{id}}/admins",
  invite_admin: "/api/organizations/{{id}}/admins/invite",
  get_admins: "/api/organizations/{{id}}/admins",
  get_user: "/api/current_organization/users/{{userId}}",
  add_user: "/api/current_organization/users",
  invite_user: "/api/current_organization/users/invite",
  get_invited_users: "/api/current_organization/users/invites",
  get_invited_admins: "/api/organizations/{{id}}/admins/invites",
  update_userrole: "/api/current_organization/users/{{userId}}",
  delete_user: "/api/current_organization/users/{{userId}}",
  get_all: "/api/organizations",
  add_new: "/api/organizations",
  get_all_users_in_current: "/api/current_organization/users",
  add_new_user_to_current: "/api/current_organization/users",
  get_current: "/api/current_organization",
  search_all_users: "/api/users?searchTerm={{searchTerm}}",
  get_any_user: "/api/user/{{id}}",
};

//ORGANIZATIONS
export const getOrganisation = async (id: string, token: string) => {
  const endpoint: string = baseUrl + endpoints.get.replace("{{id}}", id);
  const result = await http.get(endpoint, token, null, undefined);
  checkStatusCode(result.statusCode);
  return result;
};

export const updateOrganisation = async (
  id: string,
  updatedOrganization: IOrgWithRoles,
  token: string,
) => {
  const endpoint: string = baseUrl + endpoints.update.replace("{{id}}", id);
  const result = await http.put(
    endpoint,
    token,
    updatedOrganization,
    undefined,
  );
  checkStatusCode(result.statusCode);
  return result;
};

export const deleteOrganisation = async (id: string, token: string) => {
  const endpoint: string = baseUrl + endpoints.delete.replace("{{id}}", id);
  const result = await http.delete(endpoint, token, undefined);
  checkStatusCode(result.statusCode);
  return result;
};

export const getAllOrganisations = async (
  filter: string | undefined,
  token: string,
) => {
  const filterParams =
    filter && filter.length ? "?search=" + encodeURIComponent(filter) : "";
  const endpoint: string = baseUrl + endpoints.get_all + filterParams;
  const result = await http.get(endpoint, token, null, undefined);
  checkStatusCode(result.statusCode);
  return result;
};

export const addNewOrganisation = async (
  newOrganization: IOrgWithRoles,
  token: string,
) => {
  const endpoint: string = baseUrl + endpoints.add_new;
  const result = await http.post(endpoint, token, newOrganization, undefined);
  checkStatusCode(result.statusCode);
  return result;
};

export const addAdminToOrganisation = async (
  organisationId: string,
  newAdmin: string,
  token: string,
) => {
  const endpoint: string =
    baseUrl + endpoints.add_admin.replace("{{id}}", organisationId);
  const result = await http.post(endpoint, token, newAdmin, undefined);
  checkStatusCode(result.statusCode);
  return result;
};

export const inviteAdminToOrganisation = async (
  organisationId: string,
  newAdmin: IUser,
  token: string,
) => {
  const endpoint: string =
    baseUrl + endpoints.invite_admin.replace("{{id}}", organisationId);
  const result = await http.post(endpoint, token, newAdmin, undefined);
  checkStatusCode(result.statusCode);
  return result;
};

export const getAdminsForOrganization = async (
  organisationId: string,
  token: string,
) => {
  const endpoint: string =
    baseUrl + endpoints.get_admins.replace("{{id}}", organisationId);
  const result = await http.get(endpoint, token, null, undefined);
  checkStatusCode(result.statusCode);
  return result;
};

export const getInvitedUsersForCurrentOrganization = async (
  token: string,
) => {
  const endpoint: string =
    baseUrl + endpoints.get_invited_users;
  const result = await http.get(endpoint, token, null, undefined);
  checkStatusCode(result.statusCode);
  return result;
};

export const getInvitedAdminsForOrganization = async (
  organisationId: string,
  token: string,
) => {
  const endpoint: string =
    baseUrl + endpoints.get_invited_admins.replace("{{id}}", organisationId);
  const result = await http.get(endpoint, token, null, undefined);
  checkStatusCode(result.statusCode);
  return result;
};

// CURRENT_ORGANIZATION
export const getCurrentOrganisation = async (token: string) => {
  const endpoint: string = baseUrl + endpoints.get_current;
  const result = await http.get(endpoint, token, null, undefined);
  checkStatusCode(result.statusCode);
  return result;
};

export const getAllUsersForCurrentOrganisation = async (
  filter: string | undefined,
  token: string,
) => {
  const filterParams =
    filter && filter.length ? "?search=" + encodeURIComponent(filter) : "";
  const endpoint: string =
    baseUrl + endpoints.get_all_users_in_current + filterParams;
  const result = await http.get(endpoint, token, null, undefined);
  checkStatusCode(result.statusCode);
  return result;
};

export const addNewUserToCurrentOrganisation = async (
  userDetails: IUserWithRoles,
  token: string,
) => {
  const endpoint: string = baseUrl + endpoints.add_user;
  const result = await http.post(endpoint, token, userDetails, undefined);
  checkStatusCode(result.statusCode);
  return result;
};

export const inviteNewUserToCurrentOrganisation = async (
  userDetails: IUserWithRoles,
  token: string,
) => {
  const endpoint: string = baseUrl + endpoints.invite_user;
  const result = await http.post(endpoint, token, userDetails, undefined);
  checkStatusCode(result.statusCode);
  return result;
};

export const getUserForCurrentOrganisation = async (
  userId: string,
  token: string,
) => {
  const endpoint: string =
    baseUrl + endpoints.get_user.replace("{{userId}}", userId);
  const result = await http.get(endpoint, token, null, undefined);
  checkStatusCode(result.statusCode);
  return result;
};

export const updateUserRoleForCurrentOrganisation = async (
  userId: string,
  updatedUser: IUserWithRoles,
  token: string,
) => {
  const endpoint: string =
    baseUrl + endpoints.update_userrole.replace("{{userId}}", userId);
  const result = await http.put(endpoint, token, updatedUser, undefined);
  checkStatusCode(result.statusCode);
  return result;
};

export const deleteUserForCurrentOrganisation = async (
  userId: string,
  token: string,
) => {
  const endpoint: string =
    baseUrl + endpoints.delete_user.replace("{{userId}}", userId);
  const result = await http.delete(endpoint, token, undefined);
  checkStatusCode(result.statusCode);
  return result;
};

export const searchAllUsers = async (searchTerm: string, token: string) => {
  const endpoint: string =
    baseUrl + endpoints.search_all_users.replace("{{searchTerm}}", searchTerm);
  const result = await http.get(endpoint, token, undefined);
  checkStatusCode(result.statusCode);
  return result;
};

export const getAnyUser = async (id: string, token: string) => {
  const endpoint: string =
    baseUrl + endpoints.get_any_user.replace("{{id}}", id);
  const result = await http.get(endpoint, token, undefined);
  checkStatusCode(result.statusCode);
  return result;
};

const checkStatusCode = (statusCode: number | undefined) => {
  if (!statusCode) throw new Error("Failed to execute.");

  if (statusCode === 200 || statusCode === 202) return;

  if (statusCode === 401 || statusCode === 403)
    throw new Error(t("result.unauthorized"));

  if (statusCode === 404) throw new Error(t("result.notFound"));

  if (statusCode === 409) throw new Error(t("result.conflict"));

  if (statusCode >= 400 && statusCode < 500)
    throw new Error(t("result.inputError"));

  if (statusCode >= 500 || statusCode === 302)
    throw new Error(t("result.serverError"));
};

import {
  Alert,
  Button,
  Card,
  CardContent,
  IconButton,
  List,
  ListItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useContext, useState } from "react";
import { AuthContext } from "react-oauth2-code-pkce";
import { useTranslation } from "react-i18next";
import AuthStore from "../stores/AuthStore";
import AddIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import SearchIcon from "@mui/icons-material/Search";
import ErrorIcon from "@mui/icons-material/Error";
import CheckIcon from "@mui/icons-material/Check";
import OrgStore from "../stores/OrgStore";
import { IUser } from "../models/user.types";
import { getListOfContacts, isValidEmail } from "../utils/helpers";

interface OrganizationDetailsProps {
  orgId: string;
}
const AddAdminToOrganisation: React.FC<OrganizationDetailsProps> = ({
  orgId,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { token } = useContext(AuthContext);
  const { t } = useTranslation();
  const [dirty, setDirty] = useState<boolean>(false);
  const [alert, setAlert] = useState<string | undefined>(undefined);
  const [isErrorAlert, setErrorAlert] = useState<boolean>(false);
  const [existingUsersFound, setExistingUsersFound] = useState<IUser[]>([]);
  const [inviteUser, setInviteUser] = useState<boolean>(false);

  const handleAdminFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchTerm(event.target.value);
    setDirty(true);
    setAlert(undefined);
  };

  const handleSearch = useCallback(async () => {
    if (dirty) {
      try {
        setExistingUsersFound([]);
        setInviteUser(false);
        setErrorAlert(false);

        const usersFound = await OrgStore.searchForUser(searchTerm, token);
        if (!usersFound?.length) {
          console.log("No users found...");
          if (isValidEmail(searchTerm)) {
            setInviteUser(true);
          } else {
            setErrorAlert(true);
            setAlert(t("user.not_existing_cannot_add"));
          }

          setExistingUsersFound([]);
          setDirty(false);
          return;
        }

        setExistingUsersFound(usersFound);
      } catch (e) {
        setErrorAlert(true);
        setAlert(t("result.error", { errorMessage: (e as Error).message }));
      }
      setSearchTerm("");
      setDirty(false);
    }
  }, [dirty, searchTerm, t]);

  const handleAdminAdd = useCallback(
    async (adminToAdd: string) => {
      if (adminToAdd && orgId) {
        try {
          await OrgStore.addAdminToOrganisation(orgId, adminToAdd, token);
          const filteredUsersFound = existingUsersFound.filter(
            (user) => user.id != adminToAdd,
          );
          setExistingUsersFound(filteredUsersFound);

          setErrorAlert(false);
          setAlert(t("result.success"));
        } catch (e) {
          setErrorAlert(true);
          setAlert(t("result.error", { errorMessage: (e as Error).message }));
        }
      }
      setDirty(false);
    },
    [orgId, t, existingUsersFound],
  );

  const handleAdminInvite = useCallback(
    async (adminToAdd: string) => {
      if (adminToAdd && orgId) {
        try {
          await OrgStore.inviteAdminToOrganisation(
            orgId,
            { id: adminToAdd, idType: "email" },
            token,
          );
          setInviteUser(false);
          setErrorAlert(false);
          setAlert(t("result.success"));
        } catch (e) {
          setErrorAlert(true);
          setAlert(t("result.error", { errorMessage: (e as Error).message }));
        }
      }
      setDirty(false);
    },
    [orgId, t, existingUsersFound],
  );

  return (
    <List>
      {AuthStore.isAdmin() && (
        <>
          <ListItem>
            <Typography>{t("org.add_admin")}</Typography>
          </ListItem>
          <ListItem>
            <TextField
              value={searchTerm}
              onChange={handleAdminFieldChange}
              sx={{ width: 300 }}
              label={t("org.find_user")}
              inputProps={{ onKeyDown: (e) => e.stopPropagation() }}
            ></TextField>

            <IconButton
              disabled={!dirty}
              onClick={handleSearch}
              color="primary"
            >
              <SearchIcon />
            </IconButton>
          </ListItem>
          {inviteUser && !dirty && (
            <ListItem>
              <Typography>{t("user.not_existing")}</Typography>
              <Button
                onClick={() => handleAdminInvite(searchTerm)}
                color="primary"
              >
                {t("user.invite")}
              </Button>
            </ListItem>
          )}
          {existingUsersFound.length > 0 && (
            <>
              <ListItem>
                <Typography>{t("org.select_user")}</Typography>
              </ListItem>
              {existingUsersFound.map((user: IUser) => (
                <ListItem key={user.id}>
                  <Card sx={{ width: "100%" }}>
                    <CardContent>
                      <Typography>{"id :" + user.id}</Typography>
                      {getListOfContacts(user).map((item) => (
                        <Typography key={item.type + ": " + item.contact}>
                          {item.type + ": " + item.contact}
                        </Typography>
                      ))}
                      <Button
                        onClick={() => handleAdminAdd(user.id)}
                        color="primary"
                        startIcon={<AddIcon />}
                      >
                        {t("org.add_user_button")}
                      </Button>
                    </CardContent>
                  </Card>
                </ListItem>
              ))}
            </>
          )}
        </>
      )}

      {alert && (
        <ListItem>
          <Alert
            icon={
              isErrorAlert ? (
                <ErrorIcon fontSize="inherit" />
              ) : (
                <CheckIcon fontSize="inherit" />
              )
            }
            severity={isErrorAlert ? "error" : "success"}
          >
            {alert}
          </Alert>
        </ListItem>
      )}
    </List>
  );
};

export default AddAdminToOrganisation;

import { makeAutoObservable } from "mobx";
import { themeOne } from "../styles/Theme";
import { makePersistable } from "mobx-persist-store";
import { Theme } from "@mui/material";

class SettingsStore {
  theme: Theme = themeOne;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    makePersistable(this, {
      name: "SettingsStore",
      properties: ["theme"],
      storage: window.sessionStorage,
    });
  }

  updateTheme(newTheme: Theme) {
    this.theme = newTheme;
  }
}

export default new SettingsStore();

import React, { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext } from "react-oauth2-code-pkce";
import { useTranslation } from "react-i18next";
import OrgStore from "../stores/OrgStore";
import ResourceStore from "../stores/ResourcesStore";
import { v4 as uuidv4 } from "uuid";
import {
  Alert,
  Autocomplete,
  Button,
  Chip,
  IconButton,
  List,
  ListItem,
  Stack,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import ErrorIcon from "@mui/icons-material/Error";
import CheckIcon from "@mui/icons-material/Check";

interface RefreshRequestor {
  requestRefreshCallback: (refresh: boolean) => void;
}
const AddOrganization: React.FC<RefreshRequestor> = ({
  requestRefreshCallback,
}) => {
  const { token } = useContext(AuthContext);
  const { t } = useTranslation();
  const [availableResources, setAvailableResources] = useState<string[]>([]);
  const [assignedResources, setAssignedResources] = useState<string[]>([]);
  const [dirty, setDirty] = useState<boolean>(false);
  const [alert, setAlert] = useState<string | undefined>(undefined);
  const [isErrorAlert, setErrorAlert] = useState<boolean>(false);
  const [orgName, setOrgName] = useState("");
  const [orgId, setOrgId] = useState(uuidv4());
  const [resourceToAdd, setResourceToAdd] = useState<string>("");

  useEffect(() => {
    const inner = async () => {
      setAvailableResources(await ResourceStore.getAvailableResourceNames());
    };
    inner();
  }, []);

  const handleOrgNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOrgName(event.target.value);
    setDirty(true);
    setAlert(undefined);
  };

  const handleResourceFieldChange = (event) => {
    setResourceToAdd(event.target.textContent);
  };

  const handleResourceDelete = useCallback(
    (resourceToDelete: string) => {
      const updatedResources = assignedResources.filter(
        (res) => res !== resourceToDelete,
      );
      setAssignedResources(updatedResources);
      setAvailableResources([...availableResources, resourceToDelete]);
      setDirty(true);
      setAlert(undefined);
    },
    [assignedResources, availableResources],
  );

  const handleResourceAdd = useCallback(() => {
    if (resourceToAdd == null) {
      return;
    }
    const newResourceToAdd = resourceToAdd.trim();
    if (!newResourceToAdd.trim()) {
      return;
    }

    const updatedResources = availableResources.filter(
      (res) => res !== newResourceToAdd,
    );
    setAvailableResources(updatedResources);
    setAssignedResources([...assignedResources, newResourceToAdd]);
    setDirty(true);
    setAlert(undefined);
    setResourceToAdd("");
  }, [assignedResources, resourceToAdd, availableResources]);

  const handleSave = useCallback(async () => {
    const roles: string[] = [];

    for (const res of assignedResources)
      roles.push(await ResourceStore.resourceForName(res));

    const newSavedDetails = {
      name: orgName,
      id: orgId,
      assignableRoles: roles,
    };
    console.log(newSavedDetails);
    try {
      await OrgStore.addOrganisation(newSavedDetails, token);
      setErrorAlert(false);
      setAlert(t("result.success"));
    } catch (e) {
      setErrorAlert(true);
      setAlert(t("result.error", { errorMessage: (e as Error).message }));
    }

    requestRefreshCallback(true);
    setOrgName("");
    setAssignedResources([]);
    setAvailableResources(await ResourceStore.getAvailableResourceNames());
    setOrgId(uuidv4());
    setDirty(false);
  }, [orgId, orgName, assignedResources, t, requestRefreshCallback]);

  return (
    <List>
      <ListItem>
        <Stack direction="row" spacing={1}>
          {assignedResources.map((role) => (
            <Chip
              color={role === "ROLE_ORGANIZATION_ADMIN" ? "error" : "primary"}
              variant="outlined"
              key={role}
              label={role}
              onDelete={() => handleResourceDelete(role)}
            />
          ))}
        </Stack>
      </ListItem>
      <ListItem>
        <TextField
          value={orgName}
          onChange={handleOrgNameChange}
          sx={{ width: 300 }}
          label="Organization name"
          inputProps={{ onKeyDown: (e) => e.stopPropagation() }}
        ></TextField>
      </ListItem>
      <ListItem>
        <Autocomplete
          disablePortal
          id="add_role"
          options={availableResources}
          sx={{ width: 300 }}
          value={resourceToAdd}
          onChange={handleResourceFieldChange}
          renderInput={(params) => (
            <TextField {...params} label={t("org.add_resource")} />
          )}
        />
        <IconButton onClick={handleResourceAdd} color="primary">
          <AddIcon />
        </IconButton>
      </ListItem>
      <ListItem>
        <Stack direction="row" spacing={1}>
          <Button disabled={!dirty} onClick={handleSave} color="primary">
            {t("org.create")}
          </Button>
        </Stack>
      </ListItem>
      {alert && (
        <ListItem>
          <Alert
            icon={
              isErrorAlert ? (
                <ErrorIcon fontSize="inherit" />
              ) : (
                <CheckIcon fontSize="inherit" />
              )
            }
            severity={isErrorAlert ? "error" : "success"}
          >
            {alert}
          </Alert>
        </ListItem>
      )}
    </List>
  );
};

export default AddOrganization;

import { observer } from "mobx-react";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "react-oauth2-code-pkce";
import { useTranslation } from "react-i18next";
import AuthStore from "../stores/AuthStore";
import OrgStore from "../stores/OrgStore";
import { Grid, Paper, TextField } from "@mui/material";
import { SimpleTreeView, TreeItem } from "@mui/x-tree-view";
import ApartmentIcon from "@mui/icons-material/Apartment";
import CurrentOrganizationDetails from "../components/CurrentOrganizationDetails";
import UserDetails from "../components/UserDetails";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import PhoneIcon from "@mui/icons-material/Phone";
import PersonIcon from '@mui/icons-material/Person';
import AddUserToCurrentOrganization from "../components/AddUserToCurrentOrganisation";
import AddOrganization from "../components/AddOrganization";
import OrganizationDetails from "../components/OrganizationDetails";
import AddAdminToOrganisation from "../components/AddAdminToOrganisation";
import AdminDetails from "../components/AdminDetails";
import {ContactType, IContact} from "../models/user.types";
import { v4 as uuidv4 } from "uuid";


const styles = {
  icon: {
    width: 16,
    height: 16,
  },
};

type RenderSelectionOptions = {
  type: string;
  param1?: string;
  param2?: string;
};

const Home = observer(() => {
  const { token } = useContext(AuthContext);
  const { t } = useTranslation();
  const orgViewAllowed = AuthStore.isAdmin();
  const userViewAllowed = AuthStore.isOrganisationAdmin();
  const [filter, setFilter] = React.useState<string>("");
  const [refreshUsers, setRefreshUsers] = React.useState<boolean>(false);
  const [refreshOrganizations, setRefreshOrganizations] =
    React.useState<boolean>(false);
  const [renderSelectionOptions, setRenderSelectionOptions] =
    React.useState<RenderSelectionOptions>({ type: "" });

  const itemSelectionHandler = (
    event: React.SyntheticEvent,
    itemId: string,
    isSelected: boolean,
  ) => {
    if (!isSelected) return;
    const parts = itemId.split("#");
    const type = parts[0];

    setRenderSelectionOptions({
      type,
      param1: parts[1],
      param2: parts[2],
    });
  };

  useEffect(() => {
    if (AuthStore.isOrganisationAdmin()) {
      OrgStore.fetchUsersForCurrentOrg(filter, token);
      OrgStore.fetchCurrentOrg(token);
    } else {
      OrgStore.fetchCurrentOrg(token).catch((e) => console.log(e));
    }
    if (AuthStore.isAdmin()) {
      OrgStore.fetchAllOrganizations(filter, token);
    }
  }, [AuthStore.authToken]);

  useEffect(() => {
    if (AuthStore.isAdmin() && refreshOrganizations) {
      OrgStore.fetchAllOrganizations(filter, token);
      setRefreshOrganizations(false);
    }
  }, [refreshOrganizations, filter]);

  useEffect(() => {
    if (AuthStore.isOrganisationAdmin() && refreshUsers) {
      OrgStore.fetchUsersForCurrentOrg(filter, token);
      setRefreshUsers(false);
    }
  }, [refreshUsers, filter]);

  const AdminListLoader: React.FC<{orgId : string}> = ({ orgId }) => {
    const [loading, setLoading] = useState(true);
    const [admins, setAdmins] = React.useState<string[]>([]);
    useEffect(() => {
      console.log("useEffect in AdminListLoader, orgId old: " + orgId + " loading: " + loading + " admins: " + JSON.stringify(admins));
      setLoading(true);
      OrgStore.fetchAdminsForOrganization(orgId, token).then((admins) => {
        setAdmins(admins);
        setLoading(false);
      });
    }, [orgId]);

    return (
      <>
        {loading && (
          <TreeItem
            key={orgId + "_admins_loading"}
            itemId={orgId + "_admins_loading"}
            label={t("loading")}
          />
        )}
        {admins?.map((admin) => (
          <TreeItem
            key={orgId + "_" + admin}
            itemId={"adminDetails#" + orgId + "#" + admin}
            label={admin}
          />
        ))}
      </>
    );
  };

  const OrgInviteListLoader: React.FC<{orgId : string | undefined, admins: boolean}> = ({orgId, admins}) => {
    const [loading, setLoading] = useState(true);
    const [invitees, setInvitees] = React.useState<IContact[]>([]);

    useEffect(() => {
      setLoading(true);
      if (admins) {
        OrgStore.fetchInvitedAdminsForOrganization(orgId as string, token).then((invitees) => {
          setInvitees(invitees.map(i => { return {contact: i, type: ContactType.email};}));
          setLoading(false)
        })
      } else {
        OrgStore.fetchInvitedUsersForCurrentOrganization(token).then((invitees) => {
          setInvitees(invitees);
          setLoading(false);
        });
      }
    }, [orgId, admins]);

    return (
      <>
        {loading && (
          <TreeItem
            key={orgId + (admins ? "_invitees_loading" : "_admin_invitees_loading")}
            itemId={orgId + (admins ? "_invitees_loading" : "_admin_invitees_loading")}
            label={t("loading")}
          />
        )}
        {invitees?.map((invitee, index) => (
          <TreeItem
            key={orgId + "_" + invitee.contact + "_" + uuidv4() }
            itemId={"inviteeDetails#" + orgId + "#" + invitee.contact + "#" + uuidv4()}
            label={<><EmailIcon sx={styles.icon} />&nbsp;&nbsp;{fitToDisplayTree(invitee.contact)}{" "}</>}
          />
        ))}
      </>
    );
  }
  const fitToDisplayTree = (text: string): string => {
    if (text.length < 30) return text;
    return text.slice(0, 20) + "..." + text.slice(text.length - 7);
  };

  return (
    <Paper
      sx={{
        margin: "4vw 10vw",
        padding: "20px",
        bgcolor: "primary.light",
        borderRadius: "24px",
      }}
      elevation={0}
    >
      <h2 color="primary">
        {t("home.welcome", { name: AuthStore.getCommonName() })}
      </h2>
      <h3 color="primary">{t("home.role", { role: AuthStore.getRole() })}</h3>
      <TextField
        value={filter}
        onChange={(e) => {
          setFilter(e.target.value);
          if (e.target.value.length >= 3 || e.target.value.length === 0) {
            setRefreshOrganizations(true);
            setRefreshUsers(true);
          }
        }}
        sx={{ width: 300 }}
        label="Search..."
        inputProps={{ onKeyDown: (e) => e.stopPropagation() }}
      ></TextField>
      <Grid
        container
        spacing={2}
        sx={{ paddingTop: "20px", minHeight: "300px", height: "100%" }}
      >
        <Grid item xs={12} md={4}>
          <Paper
            sx={{
              width: "100%",
              height: "100%",
              alignSelf: "flex-start",
              flexGrow: 1,
            }}
          >
            <SimpleTreeView
              sx={{ padding: 0, margin: 0 }}
              onItemSelectionToggle={itemSelectionHandler}
            >
              <TreeItem
                itemId="currentOrg"
                label={
                  <>
                    <ApartmentIcon sx={styles.icon} />
                    &nbsp;&nbsp;
                    {t("org.current_org", {
                      name: OrgStore?.currentOrganisation?.name ?? "",
                    })}
                  </>
                }
              >
                <TreeItem itemId="currentOrgDetails" label={t("details")} />
                {userViewAllowed && (
                  <>
                    <TreeItem itemId="currentOrgUsers" label={t("user.all")}>
                      <TreeItem itemId={"currentOrgAddUser"} label={t("org.add_new")}>
                      </TreeItem>
                      <TreeItem itemId={"currentOrgInvitees"} label={t("org.invited_users")} >
                        <OrgInviteListLoader orgId={OrgStore?.currentOrganisation?.id} admins={false} />
                      </TreeItem>
                      {OrgStore.users?.content.map((row) => (
                        <TreeItem
                          itemId={"currentOrgUser#" + row.id}
                          key={row.id}
                          label={
                            <>
                              <PersonIcon sx={styles.icon} />
                              &nbsp;&nbsp;{fitToDisplayTree(row.id)}{" "}
                            </>
                          }
                        ></TreeItem>
                      ))}
                    </TreeItem>
                  </>
                )}
              </TreeItem>

              {orgViewAllowed && (
                <TreeItem itemId="organisations" label={t("org.all")}>
                  <>
                    <TreeItem itemId="addOrg" label={t("org.new")} />
                    {OrgStore.organisations?.content.map((row) => (
                      <TreeItem
                        itemId={"orgDetails#" + row.id}
                        key={row.id}
                        label={
                          <>
                            <ApartmentIcon sx={styles.icon} />
                            &nbsp;&nbsp;{row.name}{" "}
                          </>
                        }
                      >
                        <TreeItem
                          itemId={"admins#" + row.id}
                          label={t("org.admins")}
                        >
                          <TreeItem itemId={"addAdmin#" + row.id}
                                    label={t("org.add_new_admin")}>
                          </TreeItem>
                          <TreeItem itemId={"orgAdminInvitees_" + row.id} label={t("org.invited_users")} >
                            <OrgInviteListLoader orgId={row.id} admins={true} />
                          </TreeItem>
                          <AdminListLoader orgId={row.id} />
                        </TreeItem>
                      </TreeItem>
                    ))}
                  </>
                </TreeItem>
              )}
            </SimpleTreeView>
          </Paper>
        </Grid>
        <Grid item xs={12} md={8}>
          <Paper
            sx={{
              width: "100%",
              height: "100%",
              backgroundColor: "white",
              alignSelf: "flex-start",
              flexGrow: 1,
            }}
          >
            {renderSelectionOptions.type === "currentOrgDetails" && (
              <CurrentOrganizationDetails />
            )}
            {renderSelectionOptions.type === "currentOrgAddUser" && (
              <AddUserToCurrentOrganization
                requestRefreshCallback={setRefreshUsers}
              />
            )}
            {renderSelectionOptions.type === "currentOrgUser" && (
              <UserDetails
                id={renderSelectionOptions.param1 as string}
                requestRefreshCallback={(value: boolean) => {
                  setRenderSelectionOptions({ type: "" });
                  setRefreshUsers(value);
                }}
              />
            )}
            {renderSelectionOptions.type === "addOrg" && (
              <AddOrganization
                requestRefreshCallback={setRefreshOrganizations}
              />
            )}
            {renderSelectionOptions.type === "orgDetails" && (
              <OrganizationDetails
                orgId={renderSelectionOptions.param1 as string}
                requestRefreshCallback={(value: boolean) => {
                  setRenderSelectionOptions({ type: "" });
                  setRefreshOrganizations(value);
                }}
              />
            )}
            {renderSelectionOptions.type === "addAdmin" && (
              <AddAdminToOrganisation
                orgId={renderSelectionOptions.param1 as string}
              />
            )}
            {renderSelectionOptions.type === "adminDetails" && (
              <AdminDetails id={renderSelectionOptions.param2 as string} />
            )}
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
});

export default Home;

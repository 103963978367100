import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

const LoggedOut = observer(() => {
  const { t } = useTranslation();

  return <h2>{t("loggedout.title")}</h2>;
});

export default LoggedOut;

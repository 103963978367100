/**
 * For each class (e.g. primary, warning etc.) you have the folowing properties:
 *
 * {
 *    main: "",
 *    light: "",
 *    dark: "",
 *    contrastText: ""
 * }
 *
 */

import { Theme, createTheme } from '@mui/material';

export const themeOne: Theme = createTheme({
  palette: {
    primary: {
      light: "#DDDDFF",
      main: "#001180",
      contrastText: "#FFFFFF"
    },
    secondary: {
      main: "#BAF41E",
      contrastText: "#001180"
    },
    error: {
      main: "#D93535",
      contrastText: "#FFFFFF"
    },
    success: {
      main: "#299853",
      contrastText: "#FFFFFF"
    },
    warning: {
      main: "#FFA000",
      contrastText: "#FFFFFF"
    },
    info: {
      main: "#EBF5F7",
      contrastText: "#001180"
    }
  }
});


export const themeTwo : Theme = createTheme({
  palette: {
    primary: {
      main: "#9bb26c",
      contrastText: "#FFFFFF"
    },
    secondary: {
      main: "#614a90",
      contrastText: "#FFFFFF"
    },
    error: {
      main: "#E57373",
      contrastText: "#FFFFFF"
    },
    success: {
      main: "#81C784",
      contrastText: "#FFFFFF"
    },
    warning: {
      main: "#FFB74D",
      contrastText: "#FFFFFF"
    },
    info: {
      main: "#64B5F6",
      contrastText: "#FFFFFF"
    }
  }
});

export const themeThree : Theme = createTheme({
  palette: {
    primary: {
      main: "#385e63",
      contrastText: "#FFFFFF"
    },
    secondary: {
      main: "#cba49f",
      contrastText: "#FFFFFF"
    },
    error: {
      main: "#D32F2F",
      contrastText: "#FFFFFF"
    },
    success: {
      main: "#4CAF50",
      contrastText: "#FFFFFF"
    },
    warning: {
      main: "#FF9800",
      contrastText: "#FFFFFF"
    },
    info: {
      main: "#03A9F4",
      contrastText: "#FFFFFF"
    }
  }
});

import { Route, Routes } from "react-router-dom";
import React, { useContext, useEffect } from "react";
import { AuthContext, IAuthContext } from "react-oauth2-code-pkce";
import TopNavBar from "src/components/Nav/TopNavBar";
import LoggedOut from "src/views/LoggedOut";
import AuthStore from "src/stores/AuthStore";
import Home from "../views/Home";

function AppRouter() {
  const { token, error, tokenData, idTokenData }: IAuthContext =
    useContext(AuthContext);

  useEffect(() => {
    //console.log(tokenData);
    AuthStore.mapToken(tokenData, idTokenData);
  }, [token, tokenData, idTokenData]);

  if (error) {
    window.location.reload();
  }

  if (!token) {
    return (
      <>
        <TopNavBar />
        <LoggedOut />
      </>
    );
  } else {
    return (
      <>
        <TopNavBar />
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </>
    );
  }
}

export default AppRouter;
